<template>
    <div class="wrap">
        <!-- wap布局 -->
        <b-container class="wap-navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/users' }">{{$t('message.myuser')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/myaddress' }">{{$t('message.addressfrom')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.added')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <b-container class="wap-shop-list">
            <div class="wap-address-from">
                <div class="title">{{$t('message.shoaddress')}}</div>
                <div class="wap-select">
                    <div class="wap-select-tit">{{$t('message.conuycity')}}</div>
                    <select class="wap-select">
                        <option value="Italia" selected>Italia</option>
                    </select>
                    <span class="el-icon-arrow-down"></span>
                </div>
                <div class="input"><input type="text" :placeholder="$t('message.province')" v-model="addressParamsObj.province" @blur="blurText" /></div>
                <div class="input"><input type="text" :placeholder="$t('message.name')" v-model="addressParamsObj.name" /></div>
                <div class="input"><input type="text" :placeholder="$t('message.surname')" v-model="addressParamsObj.family_name" /></div>
                <div class="input"><input type="text" :placeholder="$t('message.address')" v-model="addressParamsObj.info" /></div>
                <!-- <div class="input"><input type="text" :placeholder="$t('message.doornum')" v-model="addressParamsObj.apartment" /></div>
                <div class="input input_odd"><input type="text" :placeholder="$t('message.doorling')" v-model="addressParamsObj.doorbell"/></div> -->
                <div class="input">
                    <input type="text" :placeholder="$t('message.city')"  v-model="addressParamsObj.city"/>
                </div>
                <div class="input">
                    <input type="text" :placeholder="$t('message.postcode')" @blur="blurTextCode" v-model="addressParamsObj.postcode"/>
                </div>
                <div class="input"><input type="text" :placeholder="$t('message.telep')" v-model="addressParamsObj.mobile" /></div>
                <div class="link">
                    <b-row>
                        <b-col class="col-ft"><span class="icon_checkbox" @click="addressParamsObj.is_default=!addressParamsObj.is_default" :class="{'checked':addressParamsObj.is_default==1}"></span>{{$t('message.defaultcheck')}}</b-col>
                    </b-row>
                </div>
                <div class="link"><span class="linkbtn" @click="getSubmitUpdata">{{$t('message.addsub')}}</span></div>
            </div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {jsReg,yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'myaddressadd',
    data(){
        return{
            token:'',
            infoid:'',
            typeObj:1,
            addflag:false,
            languageName:'',
            languageObj:{},
            addressParamsObj:{
                is_default:1,//1是选中，0是未选中
                country:'it',//（国家 添加时必填）、
                province:'',
                city:'',//（城市 添加时必填）、
                info:'',//（详细地址 添加时必填）、
                family_name:'',//（姓 添加时必填）、
                name:'',//（名 添加时必填）
                mobile:'',//（手机号 添加时必填）、
                // apartment:'',//（公寓、门牌号 添加时必填）、
                // doorbell:'',//（门铃 添加时必填）
                postcode:'',//（邮编 添加时必填）、
            },
        }
    },
    methods: {
        
        getSubmitUpdata(){
            if(this.token){
                if(this.addressParamsObj.country==''){
                    ElementUI.Message({
                        message:this.languageObj.areatip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.name==''){
                    ElementUI.Message({
                        message:this.languageObj.nametip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.family_name==''){
                    ElementUI.Message({
                        message:this.languageObj.lasnametip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.info==''){
                    ElementUI.Message({
                        message:this.languageObj.addresstip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
                // else if(this.addressParamsObj.apartment==''){
                //     ElementUI.Message({
                //         message:this.languageObj.apartmenttip,
                //         type:'error',
                //         center: true,
                //         offset:10,
                //     });
                //     return false;
                // }
                // else if(this.addressParamsObj.doorbell==''){
                //     ElementUI.Message({
                //         message:this.languageObj.doortip,
                //         type:'error',
                //         center: true,
                //         offset:10,
                //     });
                //     return false;
                // }
                else if(this.addressParamsObj.city==''){
                    ElementUI.Message({
                        message:this.languageObj.citytip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.postcode==''){
                    ElementUI.Message({
                        message:this.languageObj.postcodetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.mobile==''){
                    ElementUI.Message({
                        message:this.languageObj.telepstip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.number.test(this.addressParamsObj.mobile)){
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    this.addressParamsObj = {
                        token:this.token,//（用户token）、
                        id:this.addressParamsObj.id,//（数据id 修改时必填）、
                        country:this.addressParamsObj.country,//（国家 添加时必填）、
                        province:this.addressParamsObj.province,//省 物流创建请求 此数据必填
                        city:this.addressParamsObj.city,//（城市 添加时必填）、
                        info:this.addressParamsObj.info,//（详细地址 添加时必填）、
                        family_name:this.addressParamsObj.family_name,//（姓 添加时必填）、
                        name:this.addressParamsObj.name,//（名 添加时必填）
                        mobile:this.addressParamsObj.mobile,//（手机号 添加时必填）、
                        is_default:this.addressParamsObj.is_default?1:0,//（是否为默认地址 1是 0否 默认0）、
                        // apartment:this.addressParamsObj.apartment,//（公寓、门牌号 添加时必填）、
                        // doorbell:this.addressParamsObj.doorbell,//（门铃 添加时必填）
                        postcode:this.addressParamsObj.postcode,//（邮编 添加时必填）、
                        is_add:this.typeObj==1?1:0,//（是否添加 1是 0否 默认0 is_edit、is_add 二者其一必填）、
                        is_edit:this.typeObj==2?1:0,//（是否编辑 1是 0否 默认0 is_edit、is_add 二者其一必填）
                    };
                    this.$http.api_user_fromaddresstoken(this.addressParamsObj).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    ElementUI.Message({
                                        message:this.typeObj==2?this.languageObj.updatetip:this.languageObj.addnewaddress,
                                        type:'success',
                                        center: true,
                                        offset:10,
                                    });
                                    setTimeout(()=>{
                                        this.$router.push({path:'/myaddress'});
                                        delLocalItem('address_user_info');
                                    },1000);
                                }
                            }else if(res.code==-1){
                                this.$store.commit('setLoginNameStatus', {});
                                delLocalItem('login_user_info');
                                this.$root.Bus.$emit('userInfoClick', {});
                                this.$store.commit('setLoginStatus',true);
                                this.$router.push({path:'/'});
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        blurText(){
            this.$http.api_datalist_getzipcodebyprovince({
                province:this.addressParamsObj.province,
                country:'it',//this.languageName
            }).then((res)=>{
                if(res){
                    this.addressParamsObj.postcode = '';
                    if(res.code==1){
                        if(res.data){
                            this.addressParamsObj.postcode = res.data.zip_code;
                        }
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.province_codepost,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        blurTextCode(){
            this.$http.api_datalist_getprovincebyzipcode({
                zipcode:this.addressParamsObj.postcode,//邮编
                // country:this.languageName
            }).then((res)=>{
                if(res){
                    this.addressParamsObj.province = '';
                    if(res.code==1){
                        if(res.data&&res.data.length>0){
                            this.addressParamsObj.province = res.data[0].province;
                        }
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.codepost_province,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "logintip":"请先登录",
                "areatip":"请选择国家/地区",
                "provincetip":"请输入省份",
                "nametip":"请输入您的名",
                "lasnametip":"请输入您的姓",
                "addresstip":"请输入您的地址",
                // "apartmenttip":"请输入公寓、门牌号等",
                // "doortip":"请输入门铃",
                "postcodetip":"请输入邮编 ",
                "telepstip":"请输入您的电话",
                "phonerighttip":"请输入正确的手机号",
                "citytip":"请输入您的城市",
                "addnewaddress":"增加地址成功",
                "updatetip":"修改地址成功",
                "province_codepost":"您输入的省份没有邮编",
                "codepost_province":"您输入的邮编没有省份",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "logintip":"please log in first",
                "areatip":"select a country",
                "provincetip":"enter province",
                "nametip":"enter your name",
                "lasnametip":"enter your last name",
                "addresstip":"enter your address",
                // "apartmenttip":"enter the apartment, house number, etc.",
                // "doortip":"enter the doorbell",
                "postcodetip":"enter zip code",
                "telepstip":"enter your phone",
                "addnewaddress":"Address added successfully",
                "citytip":"enter your city",
                "updatetip":"Modify the address successfully",
                "phonerighttip":"Please enter the correct mobile number",
                "province_codepost":"The province you entered does not have a zip code",
                "codepost_province":"The zip code you entered has no province",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "logintip":"fai prima il log in",
                "areatip":"seleziona un paese",
                "provincetip":"inserirsci la provincia",
                "nametip":"inserisci il tuo nome",
                "lasnametip":"inserisci il tuo cognome",
                "addresstip":"inserisci il tuo indirizzo",
                // "apartmenttip":"inserisci l'appartamento, il numero civico, ecc.",
                // "doortip":"inserisci il campanello",
                "postcodetip":"inserisci il codice postale",
                "telepstip":"inserisci il tuo telefono",
                "addnewaddress":"Indirizzo aggiunto correttamente",
                "citytip":"inserisci la tua città",
                "updatetip":"Modifica indirizzo andato a buon fine",
                "phonerighttip":"Inserisci il numero di cellulare corretto",
                "province_codepost":"La provincia inserita non ha un codice postale",
                "codepost_province":"Il codice postale che hai inserito non ha provincia",
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            let routes = this.$route.query;//地址栏参数
            if(routes){
                this.typeObj = routes.type;
                if(routes.type==2){
                    this.infoid = routes.id;
                    let address_user_info = getLocalItem('address_user_info')?getLocalItem('address_user_info'):{};
                    if(address_user_info){
                        this.addressParamsObj = address_user_info;
                        this.addressParamsObj.is_default = 1;
                    }
                }
            }
        }else{
            // ElementUI.Message({
            //     message:this.languageObj.logintip,
            //     type:'error',
            //     center: true,
            //     offset:10,
            // });
            // this.$store.commit('setLoginStatus',true);
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>
.wap-navtab{
    padding:20px 10px 10px;
    
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .wap-shop-list{
            padding:0 10px;
            .wap-address-from{
                padding:0 0 20px 0;
                position: relative;
               
                .title{
                    font-size:18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                .wap-select{
                    width: 100%;
                    height:58px;
                    margin-bottom: 20px;
                    padding:0 20px;
                    border-radius:5px;
                    border:1px solid #DDE0ED;
                    position: relative;
                    cursor: pointer;
                    .wap-select{
                        display: flex;
                        height: auto;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding: 0;
                        margin: 0 0px;
                        background: transparent;
                        appearance:none;   
                        -moz-appearance:none;   
                        -webkit-appearance:none; 
                    }
                    .wap-one_select{
                        height:58px;
                        line-height:58px;
                        width: 100%;
                        border: none;
                        outline: none;
                        background: transparent;
                        appearance:none;   
                        -moz-appearance:none;   
                        -webkit-appearance:none; 
                    }
                    .el-icon-arrow-down{
                        position: absolute;
                        right: 20px;
                        top:20px;
                    }
                    .wap-select-tit{
                        width: 100%;
                        height:28px;
                        line-height:28px;
                        
                        color: #95959E;
                    }
                }
                
                .input_odd{
                    margin-bottom: 20px;
                }
                
                .input{
                    height: 58px;
                    width: 100%;
                    border:1px solid #eee;
                    margin-top: 20px;
                    border-radius:5px;
                    input{
                        height: 100%;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding:0 20px;
                        background: transparent;
                    }
                }
                .link{
                    height: 40px;
                    width: 100%;
                    margin-bottom: 20px;
                    .col-ft{
                        margin-top: 20px;
                        text-align: left;
                        .icon_checkbox{
                            float: left;
                            display: block;
                            width:20px;
                            height:20px;
                            background:url(~@/assets/images/icon_check.png) no-repeat left top;
                            background-size: 100% 100%;
                            margin-top:3px;
                            margin-right:8px;
                            cursor: pointer;
                            border-radius: 3px;
                        }
                        .checked{
                            background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                    }
                    .linkbtn{
                        display: inline-block;
                        padding:10px 20px;
                        width: 100%;
                        text-align: center;
                        border-radius:4px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .wap-shop-list{
            padding:0 10px;
            .wap-address-from{
                padding:0 0 20px 0;
                position: relative;
               
                .title{
                    font-size:18px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                .wap-select{
                    width: 100%;
                    height:58px;
                    margin-bottom: 20px;
                    padding:0 20px;
                    border-radius:5px;
                    border:1px solid #DDE0ED;
                    position: relative;
                    cursor: pointer;
                    .wap-select{
                        display: flex;
                        height: auto;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding: 0;
                        margin: 0 0px;
                        background: transparent;
                        appearance:none;   
                        -moz-appearance:none;   
                        -webkit-appearance:none; 
                    }
                    .wap-one_select{
                        height:58px;
                        line-height:58px;
                        width: 100%;
                        border: none;
                        outline: none;
                        background: transparent;
                        appearance:none;   
                        -moz-appearance:none;   
                        -webkit-appearance:none; 
                    }
                    .el-icon-arrow-down{
                        position: absolute;
                        right: 20px;
                        top:20px;
                    }
                    .wap-select-tit{
                        width: 100%;
                        height:28px;
                        line-height:28px;
                        
                        color: #95959E;
                    }
                }
                
                .input_odd{
                    margin-bottom: 20px;
                }
                
                .input{
                    height: 58px;
                    width: 100%;
                    border:1px solid #eee;
                    margin-top: 20px;
                    border-radius:5px;
                    input{
                        height: 100%;
                        width: 100%;
                        border: none;
                        outline: none;
                        padding:0 20px;
                        background: transparent;
                    }
                }
                .link{
                    height: 40px;
                    width: 100%;
                    text-align: right;
                    margin-top: 20px;
                    .col-ft{
                        text-align: left;
                        .icon_checkbox{
                            float: left;
                            display: block;
                            width:20px;
                            height:20px;
                            background:url(~@/assets/images/icon_check.png) no-repeat left top;
                            background-size: 100% 100%;
                            margin-top:3px;
                            margin-right:8px;
                            cursor: pointer;
                            border-radius: 3px;
                        }
                        .checked{
                            background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                            background-size: 100% 100%;
                        }
                    }
                    .linkbtn{
                        display: inline-block;
                        padding:10px 20px;
                        border-radius:4px;
                        background: #222;
                        color: #fff;
                        
                        cursor: pointer;
                    }
                }
            }
        }
    }
    
}
</style>